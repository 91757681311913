<template>
  <div id="blocked">
    <h1>{{ maintitle }}</h1>
    <h2>{{ subtitle }}</h2>
    <p>
      <a href="//wode490390.cn/">back</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Blocked',
  props: {
    maintitle: String,
    subtitle: String,
  },
}
</script>

<style scoped>
h1 {
  margin: 0;
  font-size: 150px;
  line-height: 150px;
  font-weight: bold;
}
h2 {
  margin-top: 20px;
  font-size: 30px;
}
a {
  color: #42b983;
}
#blocked {
  text-align: center;
  width: 800px;
  margin-left: -400px;
  position:absolute;
  top: 30%;
  left: 50%;
}
</style>
