<template>
  <div id="app">
    <Blocked maintitle="401" subtitle="Unauthorized" />
  </div>
</template>

<script>
import Blocked from './components/Blocked.vue'

export default {
  name: 'application',
  components: {
    Blocked,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  background-color: #ffffff;
}
</style>
